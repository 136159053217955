import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';

function AboutPage(props) {

    return(
        <div>
            <Hero title={props.title} />

            <Content>
            <p>Hello, my name is Alíz. I'm a Hungarian-Canadian artist, mother and advocate for the wellbeing of animals and nature.</p>

            <p>Art has been my life's passion since the age of seven, when my parents decided to depart from our homeland at the end Soviet era and start a new life in Canada.</p>

            <p>It was through art that I began, and continue, to understand and express myself with more clarity.</p>

            <p>I've worked with a bunch of stuff through the years. I'm gonna add more details here. If you like my stuff, reach out through the contact page, or at instagram: <a href="https://www.instagram.com/_ghost_friend/" target="_blank" rel="noopener noreferrer">@_ghost_friend</a></p>
            </Content>
        </div>
    );

}

export default AboutPage;